.form-wrapper {
  display: flex;
  flex: 0 0 100%;
  max-width: 100%;
  flex-wrap: wrap;
  padding: 25px;
  background: white;

  .form-title {
    flex: 0 0 100%;
    font-weight: 500;
    font-size: 1.2em;
    margin-bottom: 20px;
  }

  .form-helper {
    opacity: .7;
    font-size: .9rem;
    margin-bottom: 20px;
  }

  .form {
    width: 100%;

    .mat-form-field {
      width: 100%;
      margin-bottom: 15px;

      &.required {
        .mat-form-field-label {
          &::after {
            content: "*";
            color: crimson;
            padding-left: 10px;
          }
        }
      }
    }
  }
}
