
// @import '~ngx-toastr/toastr';

// @import '~bootstrap/scss/functions';
// @import '~bootstrap/scss/variables';
// @import '~bootstrap/scss/mixins';
// @import '~ngx-toastr/toastr-bs4-alert';

@import './styles/_variables';

@import './styles/general';
@import './styles/forms';
@import './styles/sections';
@import './styles/tables';
// @import './styles/page';
// @import './styles/cards';
// @import './styles/forms';
// @import './styles/dialogs';
// @import './styles/section';


$colors: $grey100, $grey200, $grey300, $grey400, $grey500, $grey600, $grey700, $grey800, $grey900;
@for $i from 1 through length($colors) {
  .border-grey-#{$i} {
      border: 1px solid nth($colors, $i);
  }
  // es: .border-grey-3 --> color: $gray300
}
