// Fontawesome icons fix
.mat-icon.fal,
.mat-icon.fab,
.mat-icon.fas {
  vertical-align: initial !important;
}

// Buttons styles
a.mat-raised-button:hover {
  color: inherit;
  text-decoration: none;
}

// Misc
.cdk-global-overlay-wrapper {
  justify-content: center !important;
}

// Spacing helpers
.min-vw-50 { min-width: 50vw !important; }
.min-vh-50 { min-height: 50vh !important; }
.pt-initial {
  padding-top: 160px !important;
}


@media (max-width: 767px) {
  .pt-initial {
    padding-top: 100px !important;
  }
}
