.section {
  padding: 80px 0;

  .title {
    display: block;
    margin-bottom: 20px;
    font-size: 32px;
    line-height: 45px;
    font-weight: 700;
  }

  p,
  span {
    line-height: 1.9rem;
  }
}

@media (max-width: 767px) {
  .section {
    padding: 50px 0;
  }
}
