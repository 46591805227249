
.table-toolbar {
  background: $grey200;
  padding: 10px 10px;
}

table,
.mat-table,
.table {
  width: 100%;
}


@media (max-width: 767px) {
  table,
  .mat-table,
  .table {
    tr th,
    tr td {
      white-space: nowrap;
      padding-right: 10px;
    }
  }
}