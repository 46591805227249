$headerHeight: 60; // Header height in pixel
$footerHeight: 64; // Footer height in pixel

// Colors
$colorPrimary: #DD0031;
$colorPrimaryLight: #e6335a;
$colorPrimaryDark: #b4032a;

$white: #ffffff;
$grey100: #fbfbfb;
$grey200: #F2F3F8;
$grey300: #e9eaec;
$grey400: #dddee0;
$grey500: #999;
$grey600: #777;
$grey700: #555;
$grey800: #333;
$grey900: #111;
$black: #000;
